const IconVerified = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.488 22.5954C11.782 22.7524 12.151 22.7514 12.445 22.5944C20.457 18.2904 21.026 9.88141 21.019 7.49041C21.018 7.09641 20.784 6.74641 20.423 6.58741L12.373 3.02141C12.114 2.90741 11.819 2.90741 11.56 3.02241L3.56603 6.58841C3.21103 6.74741 2.97903 7.09141 2.97403 7.48041C2.94003 9.85941 3.41903 18.2864 11.488 22.5954ZM8.67403 11.1344L10.967 13.4274L15.26 9.13441L16.674 10.5484L10.967 16.2554L7.26003 12.5484L8.67403 11.1344Z"
        fill="#11CC89"
      />
    </svg>
  );
};

IconVerified.displayName = 'IconVerified';
export default IconVerified;
