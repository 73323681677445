export const normalisePathname = (pathname: string | null) => {
  if (!pathname) return '/';
  let _pathname = pathname;
  switch (_pathname) {
    case '/cv':
    case '/lcv':
      return '/';
    default:
      if (_pathname.indexOf('/cv') == 0) {
        _pathname = _pathname.replace('/cv', '');
      }
      if (_pathname.indexOf('/lcv') == 0) {
        _pathname = _pathname.replace('/lcv', '');
      }
  }

  return _pathname;
};
