import { useEffect, useState } from 'react';

export const isServer = typeof window === 'undefined';

export const isFirefoxBrowser =
  !isServer && navigator.userAgent.toLowerCase().includes('firefox');

export const isIOSDevice = !isServer && /iPad|iPhone/.test(navigator.userAgent);

export function useLineBrowser() {
  const [isLine, setIsLine] = useState<boolean>(false);

  useEffect(() => {
    setIsLine(navigator.userAgent.includes('Line'));
  }, []);

  return isLine;
}

export function useSafariBrowser() {
  const isBrowser =
    typeof window !== 'undefined' && typeof navigator !== 'undefined';

  const isSafari =
    isBrowser && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return isSafari;
}

export function useChromeBrowser() {
  const isBrowser =
    typeof window !== 'undefined' && typeof navigator !== 'undefined';

  const isChrome =
    isBrowser &&
    /Chrome/.test(navigator.userAgent) &&
    !/Edge|OPR/.test(navigator.userAgent);

  return isChrome;
}

export const getIOSVersion = () => {
  if (!isIOSDevice) return 0;
  const match = navigator.userAgent.match(/OS (\d+)_/);
  return match ? +match[1] : 0;
};

export function isWebGLSupported(): boolean {
  try {
    const canvas = document.createElement('canvas');
    return !!(
      canvas.getContext('webgl2') || canvas.getContext('experimental-webgl2')
    );
  } catch (e) {
    return false;
  }
}
