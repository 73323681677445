import { UTM_SOURCE_ACCESSTRADE } from '@/const/cookie';
import { CvModelClass } from '@/services/contentstack/models/cv-model-class';
import { LcvModel } from '@/services/contentstack/models/lcv-model';
import { LcvModelColor } from '@/services/contentstack/models/lcv-model-color';
import { IVehicleType } from '@/types/services/contentstack';
import { FormSubmissionData } from '@/types/tracking/tag-manager';

declare const window: any;

export const GTM_ID = process?.env?.NEXT_PUBLIC_GTM_ID || '';

export const isDebug = (asPath: string) => {
  return asPath.includes('gtm_debug');
};

function isDataLayerAvailable() {
  return typeof window.dataLayer !== 'undefined';
}

interface IDealerSearch {
  province: string;
  district: string;
  search: string;
  vehicleType: IVehicleType;
  showroom: boolean;
  service: boolean;
  bodyPaint: boolean;
  changeTire: boolean;
  overhaul: boolean;
}

export function trackDealerSearch(params: IDealerSearch) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'dealer-search', ...params });
}

interface MaintenanceSearchCv {
  category: string;
  model: string;
  year: string;
}
export function trackMaintenanceSearchCv(params: MaintenanceSearchCv) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'maintenance-search', ...params });
}

interface MaintenanceSearchLcv {
  model: string | undefined;
  engine: string | undefined;
  mileage: string;
}

export function trackMaintenanceSearchLcv(params: MaintenanceSearchLcv) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'maintenance-search', ...params });
}

interface IServiceMaintenanceListSearch {
  modelSubCategory?: string;
  modelClass?: string;
  year?: string;
}
export function trackServiceMaintenanceListSearch(
  params: IServiceMaintenanceListSearch
) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    event: 'service-maintenance-list-search',
    ...params,
  });
}

interface IServiceMaintenanceListSearchLCV {
  model: string;
  engine: string;
  mileage: string;
}
export function trackServiceMaintenanceListSearchLCV(
  params: IServiceMaintenanceListSearchLCV
) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    event: 'service-maintenance-list-search',
    ...params,
  });
}
interface ILeadInterest {
  district?: string;
  city?: string;
  dealerName: string;
  id: string;
  content_ids: string;
  model?: string;
  leadId?: string;
}
export function formatModelForFacebookTracking(value: string) {
  switch (value) {
    case 'pickup':
      return 'รถปิกอัพ';
    case 'ppv':
      return 'รถอเนกประสงค์';
  }
  return 'รถบรรทุก';
}
export function trackFBLeadInterest(params: ILeadInterest) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    event: 'submit-crp-register-interest',
    ...params,
  });
}

export function trackFBLeadFinancial(params: ILeadInterest) {
  if (!isDataLayerAvailable()) {
    return;
  }
  window.dataLayer.push({
    event: 'submit-crp-register',
    ...params,
  });
}
interface ILeadFinancialInitiated {
  content_ids: string;
}
export function trackFBLeadFinancialInitiated(params: ILeadFinancialInitiated) {
  if (!isDataLayerAvailable()) {
    return;
  }
  window.dataLayer.push({
    event: 'submit-crp-register-initiated',
    ...params,
  });
}

export function trackFBDealerSearch(params: {
  city: string;
  district: string;
}) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    event: 'dealer-submit',
    ...params,
  });
}

interface IProductListFilter {
  modelClass?: string;
  modelSubCategory?: string;
  businessType?: string;
  segmentType?: string;
  bodyType?: string;
}

export function trackProductListFilter(params: IProductListFilter) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'product-list-filter', ...params });
}

interface IProductListCompare {
  modelGroup?: string[];
  modelClass?: string;
  modelSubCategory?: string;
  businessType?: string;
  segmentType?: string;
  bodyType?: string;
}

export function trackProductListCompare(params: IProductListCompare) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'product-list-compare', ...params });
}

export function trackContentHubTag(params: { value: string }) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'contenthub-tag', ...params });
}

interface ILCVCalculatorTracking {
  id: string;
  type?: string;
  drivetrain?: string;
  color?: string;
  preferred_price_range?: string;
  interest_rate?: string;
  period?: string;
}
export function trackLCVCalculator(params: ILCVCalculatorTracking) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    event: 'calculator-submit',
    content_ids: params.id,
    content_type: params.type,
    drivetrain: params.drivetrain,
    color: params.color,
    preferred_price_range: params.preferred_price_range,
    interest_rate: params.interest_rate,
    period: params.period,
  });
}

interface IContentHub {
  tags: string[];
}

export function trackContentHub(params: IContentHub) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    event: 'contenthub-tags',
    content_ids: params.tags,
  });
}

interface IOwnerManual {
  vehicleType: string;
  year: string;
  name: string;
  url: string;
}

export function trackOwnerManual(params: IOwnerManual) {
  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({ event: 'owner-manual-download', ...params });
}

interface IFormSubmission {
  formId: string;
  formName: string;
  section?: string;
}

export function trackFormInitiated(params: IFormSubmission) {
  if (!isDataLayerAvailable()) {
    return;
  }
  window.dataLayer.push({
    event: 'form-initiated',
    ...params,
  });
}

export function trackFormSuccess(params: IFormSubmission) {
  if (!isDataLayerAvailable()) {
    return;
  }
  window.dataLayer.push({
    event: 'form-success',
    ...params,
  });
}

export function getLeadIDForAcessTrade(data: any) {
  let leadId = '';
  if (window._utm_source == UTM_SOURCE_ACCESSTRADE && data && data.length > 0) {
    leadId = data[0].leadId;
  }

  return leadId;
}

interface ITrackABTesting {
  experiment: string;
  variant: string;
  experimentEvent?: string;
}

export function trackABTesting(params: ITrackABTesting) {
  if (!isDataLayerAvailable()) {
    return;
  }
  window.dataLayer.push({
    event: 'ab-testing',
    ...params,
  });
}

interface IProductCompareOption {
  model_class?: string;
  content_type?: string;
  content_ids?: string;
  drivetrain?: string;
  price?: string;
  color?: string;
  models?: LcvModel[];
  modelColors?: LcvModelColor[];
}

export function trackGtmLcvCompareEvents({
  models,
  modelColors,
  ...rest
}: IProductCompareOption) {
  const params: any = { ...rest };

  const contentIDs = models?.map((o) => o?.uid);
  const contentType = models?.map(
    (o) => o?.lcv_model_class[0].category[0].name_th
  );
  const drivetrain = models?.map((o) => o?.name);
  const price = models?.map((o, i) => {
    const totalPrice = isNaN(o?.price ?? 0 + (modelColors?.[i]?.price ?? 0))
      ? ''
      : o?.price + (modelColors?.[i]?.price ?? 0);
    return totalPrice;
  });
  const color = modelColors?.map((o) => o?.lcv_color?.[0].name_th);

  if (contentIDs) {
    params.contentIDs = contentIDs;
  }
  if (contentType) {
    params.contentType = contentType;
  }
  if (drivetrain) {
    params.contentType = drivetrain;
  }
  if (price) {
    params.price = price;
  }
  if (color) {
    params.color = color;
  }

  if (!isDataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    event: 'product-compare-option',
    content_ids: contentIDs?.join(','),
    content_type: contentType?.join(','),
    drivetrain: drivetrain?.join(','),
    price: price?.join(','),
    color: color?.join(','),
    ...rest,
  });
}

export function trackGtmCvCompareEvents(model: CvModelClass) {
  if (!isDataLayerAvailable()) {
    return;
  }
  window.dataLayer.push({
    event: 'product-compare-option',
    model_class: model?.name,
  });
}

export function trackCustomEvents({
  event,
  value,
}: {
  event: string;
  value: string;
}) {
  if (!isDataLayerAvailable()) return;
  window.dataLayer.push({ event, value });
}

export function trackDynamicEvents({
  event_name,
  step_name,
  group_name,
  value,
}: {
  event_name: string;
  group_name?: string;
  step_name?: string;
  value?: string | number;
}) {
  if (!isDataLayerAvailable()) return;
  window.dataLayer.push({
    event: 'dynamic-event',
    event_name,
    step_name,
    group_name,
    value,
  });
}

export function trackForm({ type, params }: FormSubmissionData) {
  if (!isDataLayerAvailable()) return;

  window.dataLayer.push({
    event: type,
    ...params,
  });
}
