'use client';
import type { RumPublicApi } from '@datadog/browser-rum-core';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { isLocalhost, isSynthetics } from '@/components/shared/utility/host';
import { normalisePathname } from '@/components/shared/utility/router';
import logger from '@/lib/logger';

interface RumErrorEvent {
  type?: string;
  message?: string;
  stack?: string;
}

export const IGNORE_PATTERN = '[ignore]';

const IGNORE_ERRORS: RumErrorEvent[] = [
  {
    // NOTE: 3rd party script
    message: 'Script error.',
  },
  {
    // NOTE: 3rd party script
    message: 'Illegal invocation',
  },
  {
    // NOTE: 3rd party script
    message: "undefined is not an object (evaluating 'a.L')",
  },
  {
    // NOTE: 3rd party script
    stack: "SyntaxError: Unexpected token 'function'",
    message: "Unexpected token 'function'",
  },
  {
    // NOTE: 3rd party script
    message: "SyntaxError: Unexpected token 'function'",
  },
  {
    // NOTE: 3rd party script
    stack: 'https://script.hotjar.com/',
    message:
      'intervention: Unable to preventDefault inside passive event listener due to target being treated as passive. See https://www.chromestatus.com/feature/5093566007214080',
  },
  {
    // NOTE: 3rd party script
    message: "Unexpected token '.'",
  },
  {
    // NOTE: 3rd party script
    stack:
      'Error: ResizeObserver loop completed with undelivered notifications.',
    message: 'ResizeObserver loop completed with undelivered notifications.',
  },
  {
    // NOTE: 3rd party script
    stack: "TypeError: Cannot read properties of null (reading 'click')",
    message: "Cannot read properties of null (reading 'click')",
  },
  {
    // NOTE: 3rd party script
    message: 'ResizeObserver loop limit exceeded',
  },
  {
    // NOTE: 3rd party script
    message: 'scrollReadRandom(...) is not a function',
  },
  {
    // NOTE: 3rd party script (googlemap)
    message:
      'intervention: Ignored attempt to cancel a touchend event with cancelable=false,',
  },
  {
    // NOTE: 3rd party script (googlemap)
    message:
      'intervention: Ignored attempt to cancel a touchstart event with cancelable=false',
  },
  {
    // NOTE: 3rd party script (googlemap)
    message:
      'intervention: Ignored attempt to cancel a touchmove event with cancelable=false',
  },
  {
    // NOTE: netlify rum
    message: 'https://netlify-rum.netlify.app/netlify-rum.js',
  },
  {
    // NOTE: nextjs framework bugs
    message: 'Failed to load static props',
  },
  {
    // NOTE: nextjs framework bugs
    message: 'Invariant: attempted to hard navigate to the same URL',
  },
  {
    // NOTE: nextjs framework bugs
    message: 'Abort fetching component for route:',
  },
  {
    // NOTE: nextjs Route did not complete loading: ${route} is triggered when the system cannot load the necessary assets for a route within a 3.8-second timeout. This error is used to signal a failed route load, ensuring the application doesn't hang indefinitely.
    message: 'Error: Route did not complete loading:',
  },
  {
    // NOTE: 3rd party script
    stack: 'No stack, consider using an instance of Error',
  },
  {
    // NOTE: 3rd party script
    message: 'window.injectScript is not a function',
  },
  {
    // NOTE: 3rd party script
    message: 'doGameClick(...) is not a function',
  },
  {
    // NOTE: 3rd party script
    message: `Can't find variable: IFrameMessageHandler`,
  },
  {
    // NOTE: ignore pattern
    message: IGNORE_PATTERN,
  },
  {
    // NOTE: 3rd party script
    message: 'xbrowser is not defined',
  },
  {
    // NOTE: 3rd party script
    message:
      "Failed to execute 'setRemoteDescription' on 'RTCPeerConnection': Failed to set remote answer sdp: Called in wrong state: stable",
  },
  {
    // NOTE; 3rd party script
    message: "Can't find variable: gmo",
  },
];

const isIgnoreError = (error: RumErrorEvent): boolean => {
  return IGNORE_ERRORS.some((ignoreError) => {
    return (
      (ignoreError.message &&
        error.message &&
        error.message.includes(ignoreError.message)) ||
      (ignoreError.stack &&
        error.stack &&
        error.stack.includes(ignoreError.stack))
    );
  });
};

export const useRUMRecording = () => {
  const pathname = usePathname();
  const [rum, setRUM] = useState<RumPublicApi | null>(null);

  useEffect(() => {
    if (!rum) return;
    const VIEW_NAME = normalisePathname(pathname);
    rum.startView({ name: VIEW_NAME });
  }, [rum, pathname]);

  useEffect(() => {
    const init = async () => {
      try {
        if (isLocalhost()) return;
        if (isSynthetics()) return;

        const DATADOG_RUM = (await import('@datadog/browser-rum')).datadogRum;
        DATADOG_RUM.init({
          applicationId: process.env
            .NEXT_PUBLIC_DATADOG_APPLICATION_ID as string,
          clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
          site: 'datadoghq.com',
          service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
          env: process.env.NEXT_PUBLIC_ENV,
          allowedTracingUrls: [/https:\/\/.*\.isuzu-tis\.com/],
          version: process.env.NEXT_PUBLIC_COMMIT_ID,
          sessionSampleRate: Number(
            process.env.NEXT_PUBLIC_DATADOG_SAMPLE_RATE
          ),
          trackUserInteractions: true,
          sessionReplaySampleRate: 0,
          trackResources: true,
          trackLongTasks: true,
          trackViewsManually: true,
          beforeSend: (event) => {
            if (event.type === 'error' && isIgnoreError(event.error)) {
              event.context = { ...event.context, ignoreError: 'true' };
            }
            return true;
          },
        });

        setRUM(DATADOG_RUM);
      } catch (error) {
        logger.error(
          `${IGNORE_PATTERN} failed to load @datadog/browser-rum: ${error}`
        );
      }
    };
    init();
  }, []);
};
